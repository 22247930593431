import {initializeApp} from 'firebase/app';
import {initializeAuth, indexedDBLocalPersistence} from 'firebase/auth';

const firebaseConfig = {
	apiKey: import.meta.env['VITE_FIREBASE_API_KEY'] as string,
	authDomain: import.meta.env['VITE_FIREBASE_AUTH_DOMAIN'] as string,
	databaseURL: import.meta.env['VITE_FIREBASE_DATABASE_URL'] as string,
	projectId: import.meta.env['VITE_FIREBASE_PROJECT_ID'] as string,
	storageBucket: import.meta.env['VITE_FIREBASE_STORAGE_BUCKET'] as string,
	messagingSenderId: import.meta.env['VITE_FIREBASE_MESSAGING_SENDER_ID'] as string,
	appId: import.meta.env['VITE_FIREBASE_APP_ID'] as string,
};

export const fireApp = initializeApp(firebaseConfig);
export const auth = initializeAuth(fireApp, {persistence: indexedDBLocalPersistence});

if (import.meta.env.DEV) {
	void import('firebase/auth').then(({connectAuthEmulator}) =>
		connectAuthEmulator(auth, 'http://localhost:9099', {disableWarnings: true}),
	);
	void import('firebase/functions').then(({getFunctions, connectFunctionsEmulator}) =>
		connectFunctionsEmulator(getFunctions(fireApp), 'localhost', 5001),
	);
	void import('firebase/database').then(({getDatabase, connectDatabaseEmulator}) =>
		connectDatabaseEmulator(getDatabase(fireApp), 'localhost', 9000),
	);
	void import('firebase/storage').then(({getStorage, connectStorageEmulator}) =>
		connectStorageEmulator(getStorage(fireApp), 'localhost', 9199),
	);
	void import('firebase/firestore').then(({getFirestore, connectFirestoreEmulator}) =>
		connectFirestoreEmulator(getFirestore(fireApp), 'localhost', 8080),
	);
	void import('firebase/firestore/lite').then(({getFirestore, connectFirestoreEmulator}) =>
		connectFirestoreEmulator(getFirestore(fireApp), 'localhost', 8080),
	);
}
