import {lazy, Suspense} from 'react';

const Globe = lazy(async () => import('./globe'));

export default function GlobeWrapper({children}: JSX.ElementChildrenAttribute): JSX.Element {
	return (
		<>
			<Suspense fallback={<div className="fixed inset-0 bg-black" />}>
				<Globe />
			</Suspense>
			{children}
		</>
	);
}
