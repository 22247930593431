import {createRoot} from 'react-dom/client';
import {lazy, StrictMode, Suspense, useEffect, useState, useMemo} from 'react';
import type {User} from 'firebase/auth';
import {onIdTokenChanged} from 'firebase/auth';
import {Redirect, Route, Router, Switch} from 'wouter';
import makeMatcher from 'wouter/matcher';
import type {Key, Path} from 'path-to-regexp';
import {pathToRegexp} from 'path-to-regexp';
import {config} from '@fortawesome/fontawesome-svg-core';
import {QueryClient, QueryClientProvider} from 'react-query';
import {Language} from '@glogab/shared';
import {auth} from './firebase';
import Globe from './globe-wrapper';
import Login from './login';
import Register from './register';
import Loader from './loader';
import ResetPassword from './reset-password';
import HandleAction from './handle-action';
import UnAuthenticated from './unauthenticated';
import ToastProvider from './toast';
import {LanguageContext} from './util';

config.autoAddCss = false;

function logError(arg: PromiseRejectionEvent | Error) {
	const data = arg instanceof Event ? (arg.reason as string) : arg.message;
	navigator.sendBeacon('/log-error', data);
}

window.addEventListener('unhandledrejection', logError);

const queryClient = new QueryClient();

// TODO(reece) 2022-09-20: probably add something with the ServiceWorker to show it's downloaded

try {
	const unsub = onIdTokenChanged(auth, (user) => {
		createRoot(document.querySelector('#app')!).render(
			<StrictMode>
				<App user={user} />
			</StrictMode>,
		);
		unsub();
	});
} catch (error: unknown) {
	if (error instanceof Error) logError(error);
	throw error;
}

const strictMatcher = makeMatcher((path: Path) => {
	const keys: Key[] = [];
	const regexp = pathToRegexp(path, keys, {strict: true});
	return {keys, regexp};
});

const Authenticated = lazy(async () => import('./authenticated'));

// TODO(reece) 2022-03-17: make the login redirect save off the original url to redirect them back
// could be linked directly to a conversation for some reason

function App({user: propUser}: {user: User | null}): JSX.Element {
	const [user, setUser] = useState(propUser);
	useEffect(() => onIdTokenChanged(auth, setUser), []);

	// TODO(reece) 2022-03-25: should we always persist the language, even if they're logged out?
	const [language, setLanguage] = useState<Language | null | undefined>(() => {
		const result = Language.nullable().safeParse(localStorage.getItem('language'));
		if (result.success) {
			return result.data;
		}

		localStorage.removeItem('language');
		return null;
	});
	const languageState: ReturnType<typeof useState<Language | null>> = useMemo(
		() => [language, setLanguage],
		[language, setLanguage],
	);
	useEffect(() => {
		try {
			if (language) {
				localStorage.setItem('language', language);
			}
		} catch (error: unknown) {
			console.error('Could not set language', error);
		}
	}, [language]);

	return (
		<ToastProvider>
			<QueryClientProvider client={queryClient}>
				<LanguageContext.Provider value={languageState}>
					<Router matcher={strictMatcher}>
						<Switch>
							<Route path="/">
								<AuthGuard user={user}>
									<Globe>
										<UnAuthenticated />
									</Globe>
								</AuthGuard>
							</Route>
							<Route path="/reset-password">
								<Globe>
									<UnAuthenticated>
										<ResetPassword />
									</UnAuthenticated>
								</Globe>
							</Route>
							<Route path="/login">
								<AuthGuard user={user}>
									<Globe>
										<UnAuthenticated>
											<Login />
										</UnAuthenticated>
									</Globe>
								</AuthGuard>
							</Route>
							<Route path="/register">
								<AuthGuard user={user}>
									<Globe>
										<UnAuthenticated>
											<Register />
										</UnAuthenticated>
									</Globe>
								</AuthGuard>
							</Route>
							<Route path="/handle-action">
								<HandleAction />
							</Route>
							<Route>
								<AuthGuard required user={user}>
									{(user) => (
										<Suspense fallback={<Loader message="Loading libraries..." />}>
											<Authenticated user={user} />
										</Suspense>
									)}
								</AuthGuard>
							</Route>
						</Switch>
					</Router>
				</LanguageContext.Provider>
			</QueryClientProvider>
		</ToastProvider>
	);
}

type AuthGuardProps = {required: true; children: (user: User) => JSX.Element} | {children: JSX.Element};

function AuthGuard(props: {user: User | null} & AuthGuardProps): JSX.Element {
	if ('required' in props) {
		return props.user != null ? props.children(props.user) : <Redirect replace to="/" />;
	}

	return props.user == null ? props.children : <Redirect replace to="/dashboard" />;
}
