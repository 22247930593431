import type {Control, FieldPath, FieldValues} from 'react-hook-form';
import {useController} from 'react-hook-form';
import {ErrorMessage} from '@hookform/error-message';
import clsx from 'clsx';

type Props<T extends FieldValues = FieldValues> = {
	label: string;
	name: FieldPath<T>;
	control: Control<T, unknown>;
	rules: Record<string, unknown>;
} & JSX.IntrinsicElements['input'];

export default function FormInput<T extends FieldValues = FieldValues>(props: Props<T>): JSX.Element {
	const {label, control, rules, name, className, ...inputProps} = props;
	const {field, formState} = useController<T>({control, rules, name});
	return (
		<>
			<label htmlFor={name} className="mt-3 ml-2">
				{label}
			</label>
			<input
				{...inputProps}
				{...field}
				id={name}
				className={clsx(
					'focus-ring rounded-md border px-2 py-1 text-black transition-colors invalid:ring invalid:ring-red-500',
					className,
				)}
			/>
			<ErrorMessage
				errors={formState.errors}
				name={name as any}
				render={({message}) => <div className="text-red-500">{message}</div>}
			/>
		</>
	);
}
