import {useForm} from 'react-hook-form';
import {useMutation} from 'react-query';
import {sendEmailVerification, createUserWithEmailAndPassword, updateProfile} from 'firebase/auth';
import {getFirestore, doc, setDoc} from 'firebase/firestore/lite';
import {FirebaseError} from '@firebase/util';
import {Link} from 'wouter';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowUpRightFromSquare} from '@fortawesome/pro-solid-svg-icons';
import type {User as GlogabUser} from '@glogab/shared';
import {Dialog} from '@headlessui/react';
import {auth, fireApp} from './firebase';
import FormInput from './form-input';
import Button from './button';

type FormInputs = {
	email: string;
	password: string;
	confirm: string;
	username: string;
	terms: boolean;
};

const store = getFirestore(fireApp);

async function registerUser({email, password, username}: FormInputs) {
	const {user} = await createUserWithEmailAndPassword(auth, email, password);
	const parameters: Partial<GlogabUser> = {agreedToTerms: true, displayName: username};
	await Promise.all([
		updateProfile(user, {displayName: username}),
		sendEmailVerification(user),
		setDoc(doc(store, `users/${user.uid}`), parameters),
	]);
}

export default function Register(): JSX.Element {
	const {
		control,
		register,
		handleSubmit,
		setError,
		getValues,
		formState: {errors},
	} = useForm<FormInputs>({
		reValidateMode: 'onSubmit',
		defaultValues: {
			email: '',
			password: '',
			confirm: '',
			username: '',
			terms: false,
		},
	});

	const {isLoading, mutate} = useMutation(registerUser, {
		onError(error) {
			if (error instanceof FirebaseError) {
				switch (error.code) {
					case 'auth/email-already-in-use': {
						setError('email', {type: 'email-already-in-use', message: 'Email already in use'});
						break;
					}

					case 'auth/invalid-email': {
						setError('email', {type: 'invalid-email', message: 'Invalid Email'});
						break;
					}

					case 'auth/weak-password': {
						setError('password', {type: 'weak-password', message: 'Password is too weak'});
						break;
					}

					case 'auth/network-request-failed': {
						setError('terms', {type: 'network-failure', message: 'Network request failed, try again'});
						break;
					}

					case 'auth/too-many-requests': {
						setError('terms', {
							type: 'too-many-requests',
							message: 'Too many requests, try again in a bit',
						});
						break;
					}

					default: {
						setError('terms', {type: 'unknown-error', message: 'Unknown error, please try again later'});
						throw error;
					}
				}
			}
		},
	});

	return (
		<Dialog.Panel className="relative mx-2 rounded-lg bg-white px-3 pb-8 text-black">
			<Dialog.Title className="pt-4 text-center text-3xl">Register for an Account</Dialog.Title>
			<form noValidate className="flex flex-col px-4" onSubmit={handleSubmit(mutate as any)}>
				<FormInput
					type="text"
					autoComplete="username"
					label="Username"
					name="username"
					control={control}
					rules={{required: 'Please enter a username'}}
				/>
				<FormInput
					type="email"
					autoComplete="email"
					label="Email"
					name="email"
					control={control}
					rules={{required: 'Please enter your email', pattern: {value: /.+@.*\./, message: 'Invalid email'}}}
				/>
				<FormInput
					type="password"
					autoComplete="new-password"
					label="Password"
					name="password"
					control={control}
					rules={{
						required: 'Please enter a password',
						minLength: {value: 6, message: 'Must be minimum 6 characters'},
					}}
				/>
				<FormInput
					type="password"
					autoComplete="new-password"
					label="Confirm Password"
					name="confirm"
					control={control}
					rules={{
						required: 'Please re-enter your password',
						validate: (value: FormInputs['confirm']) =>
							value == getValues('password') ? true : 'Passwords do not match',
					}}
				/>
				<label className="mt-3 flex items-center">
					<input
						id="terms"
						type="checkbox"
						className="peer hidden"
						{...register('terms', {required: 'Required to use the service'})}
					/>
					<div className="ml-1 inline-block h-3 w-3 rounded-full bg-white ring ring-blue-500 ring-offset-2 transition peer-checked:bg-blue-200" />
					<span className="ml-2">
						I agree to the{' '}
						<a
							href="https://glogab.com/terms"
							target="_blank"
							rel="noreferrer"
							className="text-blue-200 underline"
						>
							Terms of Service
							<sup>
								<FontAwesomeIcon icon={faArrowUpRightFromSquare} />
							</sup>
						</a>
					</span>
				</label>
				{errors.terms && <div className="text-red-500">{errors.terms.message}</div>}
				<Button
					type="submit"
					isLoading={isLoading}
					className="focus-ring mt-5 rounded bg-blue-500 py-1 text-white transition-colors hover:bg-blue-700 focus:bg-blue-700 disabled:bg-gray-400"
				>
					Register
				</Button>
				<Link to="/login" className="mt-2 py-1 text-center underline transition-colors hover:text-blue-400">
					Already have an account?
				</Link>
			</form>
		</Dialog.Panel>
	);
}
