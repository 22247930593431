import {useForm} from 'react-hook-form';
import {Dialog} from '@headlessui/react';
import {sendPasswordResetEmail} from 'firebase/auth';
import {useState} from 'react';
import clsx from 'clsx';
import {auth} from './firebase';
import FormInput from './form-input';
import Button from './button';

type FormInput = {
	email: string;
};

export default function ResetPassword(): JSX.Element {
	const {control, handleSubmit} = useForm<FormInput>({
		reValidateMode: 'onSubmit',
		defaultValues: {email: ''},
	});

	const [loading, setLoading] = useState(false);
	const [wasSent, setWasSent] = useState(false);

	async function reset({email}: FormInput) {
		setLoading(true);
		try {
			await sendPasswordResetEmail(auth, email);
			setWasSent(true);
		} finally {
			setLoading(false);
		}
	}

	return (
		<Dialog.Panel className="relative mx-2 rounded-lg bg-white px-3 pb-8 text-black">
			<Dialog.Title className="pt-4 text-center text-3xl">Forgot Password</Dialog.Title>
			<form noValidate className="flex flex-col px-4" onSubmit={handleSubmit(reset)}>
				<FormInput
					label="Email"
					type="email"
					autoComplete="email"
					name="email"
					control={control}
					rules={{required: 'Email is required'}}
				/>
				<div className={clsx('ml-2 text-blue-600', {hidden: !wasSent})}>Reset email has been sent</div>
				<Button type="submit" isLoading={loading}>
					Send Reset Email
				</Button>
			</form>
		</Dialog.Panel>
	);
}
