import {useContext, useState} from 'react';
import {Listbox} from '@headlessui/react';
import clsx from 'clsx';
import {usePopper} from 'react-popper';
import type {Language} from '@glogab/shared';
import {LanguageMapping} from '@glogab/shared';
import {Breakpoint, LanguageContext, useBreakpoint} from './util';

export default function Selector({className}: {className?: string}): JSX.Element {
	const [language, setLanguage] = useContext(LanguageContext);

	const [buttonRef, setButtonRef] = useState<HTMLButtonElement | null>(null);
	const [optionsRef, setOptionsRef] = useState<HTMLUListElement | null>(null);
	const {styles, attributes} = usePopper(buttonRef, optionsRef, {
		placement: 'bottom-end',
		strategy: 'fixed',
		modifiers: [{name: 'offset', options: {offset: [0, 10]}}],
	});

	const isBreakpointLg = useBreakpoint(Breakpoint.lg);

	if (!isBreakpointLg) {
		return (
			<div className={className ?? ''}>
				<select
					value={language!}
					className="btn-outline interact:text-background interact:bg-white appearance-none text-center text-xs"
					aria-label="Select Your Language"
					onChange={(event) => {
						setLanguage(event.currentTarget.value as Language);
					}}
				>
					<option hidden>Select Your Language</option>
					{Object.entries(LanguageMapping).map(([value, label]) => (
						<option key={value} value={value}>
							{label}
						</option>
					))}
				</select>
			</div>
		);
	}

	return (
		<Listbox horizontal value={language} onChange={setLanguage}>
			<div className={clsx('relative', className)}>
				<Listbox.Button ref={setButtonRef} className="btn-outline interact:text-background interact:bg-white">
					{LanguageMapping[language!] ?? 'Select Your Language'}
				</Listbox.Button>
				<Listbox.Options
					ref={setOptionsRef}
					unmount={false}
					style={styles['popper']}
					{...attributes['popper']}
					className="bg-background z-10 mx-auto grid w-[800px] max-w-full grid-cols-6 gap-2 rounded-xl border-2 px-2 py-2 text-center outline-none"
				>
					{Object.entries(LanguageMapping).map(([value, label]) => (
						<Listbox.Option key={value} value={value}>
							{({active, selected}: {active: boolean; selected: boolean}) => (
								<div
									className={clsx('rounded p-2 transition-colors', {
										'bg-white/[0.2]': active,
										'bg-primary': selected,
									})}
								>
									{label}
								</div>
							)}
						</Listbox.Option>
					))}
				</Listbox.Options>
			</div>
		</Listbox>
	);
}
