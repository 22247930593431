import {Link, useLocation} from 'wouter';
import {Dialog} from '@headlessui/react';
import {useCallback} from 'react';
import Logo from './logo';
import LanguageSelector from './language-selector';

// TODO(reece) 2022-06-14: think about refactoring this to something like
// <Globe>
//   <Dialog>w/e</Dialog>
// </Globe>
export default function UnAuthenticated({children}: {children?: JSX.Element | JSX.Element[]}): JSX.Element {
	const [location, setLocation] = useLocation();

	const backToRoot = useCallback(() => setLocation('/'), [setLocation]);

	return (
		<>
			<div className="absolute top-0 left-0 right-0 mt-5 flex flex-col items-center md:left-5 md:right-[unset]">
				<Logo className="w-[175px]" />
				<h1 className="mt-3 text-center text-xl md:hidden">
					<span className="font-bold">Select a Language</span> <br />
					<span className="font-normal tracking-wider text-green-500">&amp; start chatting now!</span>
				</h1>
				<LanguageSelector className="mt-3 block md:hidden" />
			</div>
			<div className="absolute top-0 right-0 mt-10 mr-10 hidden text-white md:block">
				<LanguageSelector className="flex w-[35ch] justify-center" />
			</div>
			<div className="absolute bottom-0 left-0 right-0 mb-10">
				<div className="mb-5 text-center font-bold">
					<h1 className="hidden text-3xl md:block">Select a Country</h1>
					<h2 className="hidden text-xl opacity-80 md:block">&amp; start chatting now!</h2>
				</div>
				<div className="mx-auto flex justify-evenly lg:w-[500px]">
					<Link
						to="/register"
						className="btn-outline interact:bg-secondary interact:border-secondary justify-self-end text-center text-sm uppercase"
					>
						Create&nbsp;Account
					</Link>
					<Link
						to="/login"
						className="btn-outline interact:bg-primary interact:border-primary justify-self-start text-center text-sm uppercase"
					>
						Log in Now
					</Link>
				</div>
			</div>

			<Dialog
				open={location != '/'}
				className="fixed inset-0 flex items-center justify-center overflow-y-auto"
				onClose={backToRoot}
			>
				<div className="fixed inset-0 bg-black opacity-30" />
				{children}
			</Dialog>
		</>
	);
}
