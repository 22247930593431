import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {Link} from 'wouter';
import LanguageSelector from './language-selector';
import Logo from './logo';

type Props = {
	message: string;
	hasSpinner?: boolean;
};

export default function Loader({message, hasSpinner = true}: Props): JSX.Element {
	return (
		<>
			<div className="absolute inset-0 flex flex-col items-center justify-center">
				<div className="absolute inset-0 bg-black/[0.3]" />
				<h1 className="relative mb-10 text-center text-3xl font-bold sm:text-5xl">{message}</h1>
				{hasSpinner && <FontAwesomeIcon icon={faSpinner} className="animate-spin text-3xl sm:text-5xl" />}
			</div>
			<Link to="/" className="absolute top-0 left-0 mt-10 ml-10 w-2/12">
				<Logo />
			</Link>
			<div className="absolute top-0 right-0 left-0 mt-10 flex justify-center px-5 text-white sm:justify-end">
				<LanguageSelector />
			</div>
		</>
	);
}
