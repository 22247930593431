import {Fragment, createContext, useState, useContext, useCallback} from 'react';
import {createPortal} from 'react-dom';
import {Transition} from '@headlessui/react';
import {wait} from './util';

const ToastContext = createContext((_: string) => {
	// Placeholder
});
export function useToast() {
	return useContext(ToastContext);
}

export default function ToastProvider({children}: {children: JSX.Element}): JSX.Element {
	const [isShowing, setIsShowing] = useState(false);
	const [message, setMessage] = useState('');

	const showToast = useCallback(async (message_: string) => {
		setMessage(message_);
		setIsShowing(true);
		await wait(2500);
		setIsShowing(false);
	}, []);

	return (
		<ToastContext.Provider value={showToast}>
			{createPortal(
				<Transition
					as={Fragment}
					show={isShowing}
					enter="transition ease-out"
					enterFrom="opacity-0"
					leave="transition ease-in"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-x-0 bottom-10 mx-auto w-max rounded bg-white/90 px-2 py-1 text-black">
						{message}
					</div>
				</Transition>,
				document.querySelector('#toast')!,
			)}
			{children}
		</ToastContext.Provider>
	);
}
