import {createContext, useEffect, useMemo, useState} from 'react';
import type {Language} from '@glogab/shared';
import type {Paginator} from '@twilio/conversations';

export function useAsyncEffect(fn: (signal: AbortSignal) => Promise<void>, deps: unknown[]): void {
	useEffect(
		() => {
			const ac = new AbortController();
			void fn(ac.signal);
			return () => ac.abort();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		deps,
	);
}

export function placeholder() {
	// Placeholder function
}

export const LanguageContext = createContext<ReturnType<typeof useState<Language | null>>>([null, () => null]);

export async function* wrap<T>(paginator?: Promise<Paginator<T>>): AsyncGenerator<T, void, undefined> {
	if (paginator != null) {
		let currentPaginator = await paginator;
		while (true) {
			yield* currentPaginator.items;

			if (currentPaginator.hasNextPage) {
				// eslint-disable-next-line no-await-in-loop
				currentPaginator = await currentPaginator.nextPage();
			} else {
				break;
			}
		}
	}
}

export enum Breakpoint {
	sm = '(min-width: 640px)',
	md = '(min-width: 768px)',
	lg = '(min-width: 1024px)',
	xl = '(min-width: 1280px)',
	'2xl' = '(min-width: 1536px)',
}

export function useBreakpoint(breakpoint: Breakpoint): boolean {
	const mql = useMemo(() => window.matchMedia(breakpoint), [breakpoint]);
	const [matches, setMatches] = useState(mql.matches);
	useEffect(() => {
		const ac = new AbortController();
		mql.addEventListener('change', (event) => setMatches(event.matches), {
			signal: ac.signal,
		});
		return () => ac.abort();
	}, [mql]);

	return matches;
}

export function debounce<T extends (...args: unknown[]) => void>(fn: T, delay = 250): T {
	// NOTE(reece) 2022-04-06: this is *technically* wrong, but who cares
	let timeoutId: NodeJS.Timeout;

	function debounced(...args: unknown[]) {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(fn, delay, ...args);
	}

	return debounced as T;
}

export async function wait(delay = 1500) {
	return new Promise((resolve) => {
		setTimeout(() => resolve(null), delay);
	});
}
