/* eslint-disable react/button-has-type */
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

type Props = {
	className?: string;
	isLoading?: boolean;
} & JSX.ElementChildrenAttribute &
	JSX.IntrinsicElements['button'];

export default function Button(props: Props): JSX.Element {
	const {isLoading, className, ...buttonProps} = props;
	return (
		<button
			{...buttonProps}
			className={clsx(
				'focus-ring mt-6 rounded bg-blue-500 py-1 text-white transition-colors hover:bg-blue-700 focus:bg-blue-700 disabled:bg-gray-400',
				className,
			)}
			disabled={isLoading ?? false}
		>
			<span className={clsx({hidden: isLoading})}>{props.children}</span>
			{isLoading !== undefined && (
				<FontAwesomeIcon className={clsx('animate-spin text-2xl', {hidden: !isLoading})} icon={faSpinner} />
			)}
		</button>
	);
}
