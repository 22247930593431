import {FirebaseError} from '@firebase/util';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {applyActionCode, checkActionCode, confirmPasswordReset, verifyPasswordResetCode} from 'firebase/auth';
import type {PropsWithChildren} from 'react';
import {useMemo, useState} from 'react';
import {Link, Redirect, useLocation} from 'wouter';
import {z} from 'zod';
import {useForm} from 'react-hook-form';
import {auth} from './firebase';
import {useAsyncEffect} from './util';
import Logo from './logo';
import FormInput from './form-input';
import Button from './button';

const QueryParameters = z.preprocess(
	(search) => {
		const parameters = new URLSearchParams(String(search));
		return Object.fromEntries(parameters.entries());
	},
	z.object({
		mode: z.enum(['resetPassword', 'recoverEmail', 'verifyEmail']),
		oobCode: z.string(),
		apiKey: z.string(),
	}),
);

const Status = z.discriminatedUnion('type', [
	z.object({type: z.literal('loading')}),
	z.object({type: z.literal('success'), email: z.string()}),
	z.object({type: z.literal('error'), message: z.string()}),
]);
type Status = z.infer<typeof Status>;

export default function ActionHandler(): JSX.Element {
	const parameters = useMemo(() => QueryParameters.safeParse(location.search), []);

	const [status, setStatus] = useState<Status>({type: 'loading'});

	useAsyncEffect(async () => {
		if (parameters.success) {
			const {data} = parameters;
			try {
				switch (data.mode) {
					case 'recoverEmail': // Fallthrough
					case 'verifyEmail': {
						const result = await checkActionCode(auth, data.oobCode);
						await applyActionCode(auth, data.oobCode);
						setStatus({type: 'success', email: result.data.email!});
						break;
					}

					case 'resetPassword': {
						setStatus({
							type: 'success',
							email: await verifyPasswordResetCode(auth, data.oobCode),
						});
						break;
					}

					// No default
				}
			} catch (error: unknown) {
				if (error instanceof FirebaseError) {
					switch (error.code) {
						case 'auth/invalid-action-code': {
							setStatus({type: 'error', message: 'Invalid code'});
							break;
						}

						default: {
							throw error;
						}
					}
				} else {
					throw error;
				}
			}
		}
	}, [parameters]);

	if (!parameters.success) {
		return <Redirect replace to="/" />;
	}

	if (status.type == 'error') {
		return (
			<Container>
				<main className="text-center text-2xl">{status.message}</main>
			</Container>
		);
	}

	if (status.type == 'success') {
		const {data} = parameters;
		switch (data.mode) {
			case 'verifyEmail': {
				return (
					<Container>
						<main className="text-center text-2xl">
							Thank you for verifying your email,
							<br />
							you can now close this window
						</main>
					</Container>
				);
			}

			case 'recoverEmail': {
				return (
					<Container>
						<main className="text-center">
							<p className="text-2xl">
								Your email was restored to: <code>{status.email}</code>.
							</p>
							<p className="text-lg">
								If this email change was unwanted, you may want to{' '}
								<Link to="/reset-password" className="underline">
									reset your password
								</Link>
								.
							</p>
							<p className="text-lg">Otherwise, you can close this window.</p>
						</main>
					</Container>
				);
			}

			case 'resetPassword': {
				return <PasswordReset actionCode={data.oobCode} email={status.email} />;
			}

			// No default
		}
	}

	return <FontAwesomeIcon icon={faSpinner} className="mt-8 animate-spin px-5 text-2xl" />;
}

type FormInputs = {
	email: string;
	password: string;
	confirm: string;
};

function PasswordReset({actionCode, email}: {actionCode: string; email: string}): JSX.Element {
	const [, setLocation] = useLocation();
	const {control, handleSubmit, getValues} = useForm<FormInputs>({
		reValidateMode: 'onSubmit',
		defaultValues: {password: '', confirm: '', email},
	});

	const [isLoading, setIsLoading] = useState(false);
	async function reset({password}: FormInputs) {
		setIsLoading(true);
		try {
			await confirmPasswordReset(auth, actionCode, password);
			setLocation('/login');
		} catch (error: unknown) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	}

	return (
		<Container>
			<form noValidate className="flex flex-col px-4" onSubmit={handleSubmit(reset)}>
				<FormInput
					readOnly
					className="bg-gray-300 text-gray-700"
					name="email"
					control={control}
					type="email"
					autoComplete="email"
					label="Email"
					rules={{}}
				/>
				<FormInput
					name="password"
					control={control}
					type="password"
					autoComplete="new-password"
					label="New Password"
					rules={{required: 'Please enter a password', minLength: 6}}
				/>
				<FormInput
					name="confirm"
					control={control}
					type="password"
					autoComplete="new-password"
					label="Confirm Password"
					rules={{
						required: 'Please enter a password',
						validate: (value: FormInputs['confirm']) =>
							value == getValues('password') ? true : 'Passwords do not match',
					}}
				/>
				<Button isLoading={isLoading}>Reset Password</Button>
			</form>
		</Container>
	);
}

function Container({children}: PropsWithChildren<Record<string, unknown>>) {
	return (
		<div className="bg-background relative h-full w-full">
			<div className="container mx-auto flex flex-col items-center pt-5">
				<Logo className="w-[300px]" />
				{children}
			</div>
		</div>
	);
}
