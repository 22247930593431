import {useState} from 'react';
import {signInWithEmailAndPassword} from 'firebase/auth';
import {FirebaseError} from '@firebase/util';
import {Link} from 'wouter';
import {useForm} from 'react-hook-form';
import {Dialog} from '@headlessui/react';
import {auth} from './firebase';
import FormInput from './form-input';

type FormInputs = {
	email: string;
	password: string;
};

// TODO(reece) 2022-04-26: need to enforce terms of service
// TODO(reece) 2022-04-26: add reset password

export default function Login(): JSX.Element {
	const {control, handleSubmit, setError} = useForm<FormInputs>({
		reValidateMode: 'onSubmit',
		defaultValues: {email: '', password: ''},
	});

	const [loading, setLoading] = useState(false);

	async function onSubmit({email, password}: FormInputs) {
		setLoading(true);
		try {
			await signInWithEmailAndPassword(auth, email, password);
		} catch (error: unknown) {
			if (error instanceof FirebaseError) {
				switch (error.code) {
					case 'auth/invalid-email': {
						setError('email', {
							type: 'invalid-email',
							message: 'Enter a valid email',
						});
						break;
					}

					case 'auth/user-not-found': {
						setError('email', {
							type: 'user-not-found',
							message: 'Email not found',
						});
						break;
					}

					case 'auth/wrong-password': {
						setError('password', {
							type: 'wrong-password',
							message: 'Incorrect password',
						});
						break;
					}

					case 'auth/user-disabled': {
						setError('email', {
							type: 'user-banned',
							message: 'Account has been banned',
						});
						break;
					}

					case 'auth/too-many-requests': {
						setError('email', {
							type: 'too-many-requests',
							message: 'Too many requests, try again later',
						});
						break;
					}

					default:
						throw error;
				}
			} else {
				throw error;
			}
		} finally {
			setLoading(false);
		}
	}

	return (
		<Dialog.Panel className="relative mx-2 rounded-lg bg-white px-3 pb-8 text-black">
			<Dialog.Title className="pt-4 text-center text-3xl">Login</Dialog.Title>
			<form noValidate className="flex flex-col px-7" onSubmit={handleSubmit(onSubmit)}>
				<FormInput
					type="email"
					autoComplete="email"
					label="Email"
					name="email"
					control={control}
					rules={{required: 'Please enter your email'}}
				/>
				<FormInput
					type="password"
					autoComplete="current-password"
					label="Password"
					name="password"
					control={control}
					rules={{required: 'Please enter your password'}}
				/>
				<button
					type="submit"
					className="focus-ring mt-6 rounded bg-blue-500 py-1 text-white transition-colors hover:bg-blue-700 focus:bg-blue-700 disabled:bg-gray-400"
					disabled={loading}
				>
					Log In
				</button>
				<Link to="/reset-password" className="interact:text-blue-500 mt-2 transition">
					Forgot password
				</Link>
			</form>
		</Dialog.Panel>
	);
}
